*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
}

html{
  height: 100%;
}
p{
  font-weight: 500 !important;
  line-height: 1.7;
}
body::-webkit-scrollbar {
  width: 10px;
  @media(max-width:1475px){
    width: 9px;
  }
}

body::-webkit-scrollbar-track {
  background: #ece2e2;
  border-radius: 1px;
}
body::-webkit-scrollbar-thumb {
  background-color:  #427dfe;
  border-radius: 5px;
}

body {
  width: 100%;
  height: 100%;
  font-family: 'Poppings Regular', sans-serif;
}

.rec-swipable{
  align-items:stretch;
}
.rec-carousel-item-visible{
  margin:0;
  display: flex;
}

.hover-button{
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  width: fit-content;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  background: #4280EB;
  color:#fff;
  font-size: max(15px, 0.95vw);
  padding:max(10px, 0.75vw) max(25px, 2vw);
  border-radius: max(22px, 2vw);
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.hover-button:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #2d74ee;
  border-radius: max(22px, 2vw);
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hover-button:hover:before, .hover-button:focus:before, .hover-button:active:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

body.dark-mode{
  color:#fff;
  background: #060B2E;
}
body.dark-mode::-webkit-scrollbar-track {
  background: #464343;
}



body.dark-mode.theme-orange{
  background-color: #141519;
}
body.theme-orange::-webkit-scrollbar-thumb {
  background-color:  #eb8341;
  border-radius: 5px;
}



body.dark-mode.theme-green{
  background-color: #011102;
}
body.theme-green::-webkit-scrollbar-thumb {
  background-color:  #5fb364;
  border-radius: 5px;
}

body.dark-mode.theme-red{
  background-color: #100E0E;
}
body.theme-red::-webkit-scrollbar-thumb {
  background-color:  #d53333;
  border-radius: 5px;
}

body.dark-mode.theme-purple{
  background-color: #0E0024;
}
body.theme-purple::-webkit-scrollbar-thumb {
  background-color:  #6d3eb5;
  border-radius: 5px;
}